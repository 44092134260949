import { createRouter, createWebHistory } from 'vue-router'
import {isUserLoggedIn} from './utils'
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: () => import('../pages/Login.vue')
        },
         
        {
            path: '/admin',
            name: 'Admin',
            component: () => import('../pages/Admin.vue')
        },
        {
            path: '/',
            name: 'Home',
            component: () => import('../pages/Admin.vue')
        },
        {
            path: '/users',
            name: 'Users',
            component: () => import('../pages/users/User.vue')
        },
        {
            path: '/setting',
            name: 'Setting',
            component: () => import('../pages/settingApp/Setting.vue')
        },
        
        {
            path: '/whatsapps',
            name: 'Whatsapps',
            component: () => import('../pages/whatsapps/Whatsapp.vue')
        },
         
    ]
})

router.beforeEach((to, from, next) => {
    if ((to.name === 'Register'|| to.name === 'ForgotPassword'|| to.name === 'ResetPasswordForm')&& !isUserLoggedIn()) {
        next()
    }
    else if (to.name !== 'Login' && !isUserLoggedIn())
     {
        next({ name: 'Login' })
     }
    else if ((to.name === 'Login' || to.name === 'Register' ) && isUserLoggedIn()) {
        next({ name: 'Home' })
    }
    else {
        next()
    }
     

});
export default router
