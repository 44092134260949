<script setup>
 import {onMounted, ref } from "vue";
 import axios from "axios";
 const user =  ref('');
 const protocol = ref('');
const host = ref('');
 const token = localStorage.getItem("token");
 const logout = () => {
  
  axios
    .post("api/logout")
    .then((res) => {
     alert(res.data.message)
      
       
        localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/login";
       
    })
    .catch((err) => {
      alert(err.response.data.message);
      localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/login";
    });

};
 if(token){
axios.get('api/user').then((res) => {
      user.value = res.data.user;
      console.log(res.data)
     
       localStorage.setItem("user", JSON.stringify(res.data.user));
       localStorage.setItem("url_socket", res.data.url_socket);
     }).catch((err) => {
      alert(err.response.data.token);
      if(!err.response.data.token){
        logout();
      }
    });
}else{
  localStorage.removeItem("token");
  localStorage.removeItem("user");
 
}
 
onMounted(() => {
  if (typeof window !== "undefined") {
    protocol.value = window.location.protocol;
    host.value = window.location.host;
    localStorage.setItem("protocol", window.location.protocol);
    localStorage.setItem("host", window.location.host);
  }
});
</script>
<template>
  <div >
    
    <router-link v-if="token" to="/">
  <button type="button" class="btn btn-primary">الرئيسية</button>
    </router-link>
  <img alt="Vue logo" src="./assets/logo.png">
  <button v-if="token" @click="logout()" type="button" class="btn btn-danger">تسجيل الخروج</button>
  <router-view></router-view>  
  </div>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
