import { createApp } from 'vue'
import i18n from './i18n';
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import axios from 'axios';

 axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

import 'vue-select/dist/vue-select.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import router from './router';
import JsonExcel from "vue-json-excel3";
import Vue3Toast from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const app = createApp(App)
app.component('downloadExcel', JsonExcel);
// إضافة التوجيه
app.use(router);
app.use(i18n);
app.use(Vue3Toast,{autoClose: 3000});

// إذا كنت بحاجة إلى استخدام `Home` مباشرة، يمكنك القيام بذلك هنا
// app.component('Home', Home)

app.mount('#app')